<template>
  <div class="company">
    <el-breadcrumb class="breadCrumb" separator="/">
      <el-breadcrumb-item>个人中心</el-breadcrumb-item>
      <el-breadcrumb-item>用户管理</el-breadcrumb-item>
      <el-breadcrumb-item>操作员管理</el-breadcrumb-item>
      <el-breadcrumb-item>详情</el-breadcrumb-item>
    </el-breadcrumb>

    <el-form class="cardInfo mt20" ref="form" :model="form">
      <el-header class="titleOne">用户信息</el-header>
      <el-row>
        <el-form-item label="角色：" prop="userName">
          <el-input v-model="form.roleListName" class="input450" disabled></el-input>
        </el-form-item>
        <el-form-item label="手机号：" prop="userPhone">
          <el-input v-model="form.userPhone" class="input450" disabled></el-input>
        </el-form-item>
        <el-form-item label="邮箱：" prop="userEmail">
          <el-input v-model="form.userEmail" class="input450" disabled></el-input>
        </el-form-item>
        <el-form-item class="imgs" label="操作员证件：">
          <div class="img">
            <img :src="form.fontIdUrl" alt="">
          </div>
          <div class="img">
            <img :src="form.backIdUrl" alt="">
          </div>
        </el-form-item>
        <el-form-item label="状态：" prop="userStatus">
          <el-input v-model="form.userStatus=='1'?'正常':'冻结'" class="input450" disabled></el-input>
        </el-form-item>
        <el-form-item v-if="companyRole=='0'" label="法人授权委托书：" prop="collectFile">
          <a ref="noopener noreferrer" class="red mr30" :href="form.collectFile" target="_blank">查看</a>
          <span class="red" style="cursor: pointer" @click="downFile(form.collectFile,'法人授权委托书')">下载</span>
        </el-form-item>
        <el-form-item v-if="companyRole=='0'&&role=='1'" label="变更授权委托书：" prop="changeCollectFile">
          <a ref="noopener noreferrer" class="red mr30" :href="form.changeCollectFile" target="_blank">查看</a>
          <span class="red" style="cursor: pointer" @click="downFile(form.changeCollectFile,'变更授权委托书')">下载</span>
        </el-form-item>
      </el-row>
    </el-form>
  </div>
</template>

<script>
  import DelayButton from "@/views/Ticket/childrenCpn/DelayButton";
  import {mapState} from "vuex";

  export default {
    name: "Detail",
    components: {
      'DelayButton': DelayButton
    },
    data() {
      return {
        form: {},//详情数据
        role: this.$route.query.role
      }
    },
    created() {
      this.getList()
    },
    computed: {
      ...mapState([
        'companyRole'//公司角色
      ]),
    },
    methods: {
      //获取详情
      async getList() {
        const {data: res} = await this.$auth.post('/cpiaoju-auth/operatorManage/getOperatorById', this.$qs.stringify({
          operatorId: this.$route.query.id,
        }))
        if (res.code == 200) {
          this.form = res.data;
        } else {
          this.$message.error(res.msg);
        }
      },
      //下载pdf
      downFile(file, name) {
        let rowFileType = file.substring(file.lastIndexOf(".") + 1);
        if (["pdf"].includes(rowFileType)) {
          this.$auth({
            url: "/cpiaoju-auth/ocr/downloadUrlFileByName",
            method: "get",
            responseType: "blob",
            params: {
              url: file,
              fileName: name,
            },
          }).then((res) => {
            const blobPdf = new Blob([res.data], {
              type: "application/pdf",
            });
            const href = URL.createObjectURL(blobPdf); //创建新的URL表示指定的blob对象
            const a = document.createElement("a"); //创建a标签
            a.style.display = "none";
            a.href = href; // 指定下载链接
            a.download = name; //指定下载文件名
            a.click(); //触发下载
            URL.revokeObjectURL(a.href); //释放URL对象
          })
        }
      },
    },
  }
</script>

<style lang="less" scoped>
  .company {
    /deep/ .avatar-uploader .el-upload {
      position: relative;
      width: 150px;
      height: 150px;
      border: 1px dashed #D8D8D8;
      text-align: center;
    }

    .cardInfo {
      .el-row {
        padding: 20px 20px 0;
        box-sizing: border-box;
        background: #ffffff;
      }

      .ml76 {
        margin-left: 120px;
      }

      .mr30 {
        margin-right: 30px;
      }
    }

    .upImg {
      display: inline-block;

      .icon {
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-top: 40px;

        em {
          width: 36px;
          height: 36px;
          background: url("~@/assets/img/auth/tianjia_icon.png") no-repeat;
        }

        i {
          color: #999999;
          letter-spacing: 1px;
        }

      }

    }

    .upImgTip {
      margin-left: 12px;
      color: #C70009;
    }

    p.tips {
      color: #999999;
      line-height: 20px;
    }

    /deep/ .el-form-item__label {
      width: 126px;
      text-align: right;
    }

    .el-input.input394 {
      width: 394px;
    }

    .adress {
      /deep/ .el-select {
        width: 143px;
        margin-right: 10px;

        .el-input {
          width: 100%;
        }
      }

      /deep/ .el-input {
        width: 200px;
      }
    }

    .el-form-item.inlineBlock {
      display: inline-block;
      margin-right: 20px;
      margin-bottom: 0;

      /deep/ .el-form-item__content {
        display: inline-block;
      }
    }

    .ml76 {
      margin-left: 76px;
    }

    .upFileTip {
      color: #999999;
      margin-left: 10px;
    }

    /*按钮*/

    .upFileBtn {
      background: #ffffff;
      color: #C70009;
      border-color: #C70009;
      font-size: 14px;

      i {
        display: inline-block;
        width: 14px;
        height: 14px;
        background: url("~@/assets/img/auth/sc_02.png") no-repeat center;
        margin-right: 4px;
      }
    }

    .upFileBtn:hover {
      background: #C70009;
      border-color: #C70009;
      color: #ffffff;

      i {
        background: url("~@/assets/img/auth/sc_01.png") no-repeat center;
      }
    }

    .imgs {
      .img {
        display: inline-block;
        margin-right: 20px;
        width: 150px;
        height: 150px;
        border: 1px solid #D8D8D8;

        img {
          width: 150px;
          height: 150px;
        }
      }
    }
  }

</style>
<style>
  .company .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .company .avatar-uploader .el-upload:hover {
    /*border-color: #409EFF;*/
  }

  .company .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 150px;
    height: 150px;
    line-height: 150px;
    text-align: center;
  }

  .company .avatar {
    width: 150px !important;
    height: 150px !important;
    display: block;
  }

</style>